<script>
import VxDialogView from "@/components/vx/VxDialogView";
import ShiftForm from "@/components/daybook/shifts/ShiftForm";
import { hasSnackbarAccess } from "@/mixins/snackbar";
import { SHIFT_DIALOG_EDIT } from "./ShiftQuery.gql";
import { UPDATE_SHIFT } from "./ShiftMutation.gql";

export default {
  name: "EditShiftDialog",
  components: {
    VxDialogView,
    ShiftForm,
  },
  mixins: [hasSnackbarAccess],
  data: () => ({
    isDetailed: false,
  }),
  computed: {
    shiftId() {
      return this.$route.params.shiftId;
    },
  },
  apollo: {
    shift: {
      query: SHIFT_DIALOG_EDIT,
      variables() {
        return {
          id: this.shiftId,
        };
      },
    },
  },
  methods: {
    async updateShift(data) {
      try {
        const {
          data: { errors },
        } = await this.$apollo.mutate({
          mutation: UPDATE_SHIFT,
          variables: data,
        });
        // GraphQL errors
        if (errors && errors.length) {
          throw new Error(errors.join("; "));
        }
        // Success
        this.showSnackbar({ text: `Shift updated successfully` });
        this.$router.go(-1);
      } catch (error) {
        this.showSnackbar({ text: `Error updating shift: ${error}` });
      }
    },
  },
};
</script>

<template>
  <VxDialogView title="Edit shift">
    <ShiftForm :shift="shift" @update="updateShift" />
  </VxDialogView>
</template>
